import {combineReducers} from 'redux'
import transactions from "./transactions";
import sidebar from "./sidebar";
import vouchers from './vouchers'
import cards from "./cards";
import bookings from "@/store/reducers/admin/bookings";
import documents from './documents';

export default combineReducers({
    transactions,
    sidebar,
    vouchers,
    cards,
    bookings,
    documents,
})
