import React from 'react';
import { Provider } from 'react-redux';
import { wrapper } from '@/store';
import '../styles/main-dashboard.scss';
import type {AppPropsWithLayout} from "@/layouts/types/nextPageLayout";
import {AuthProvider} from "@/context/AuthContext";
import dynamic from 'next/dynamic';

import { Poppins } from 'next/font/google';

const poppins = Poppins({
    weight: ['100', '200', '300', '400', '500', '600', '700', '800'],
    subsets: ['latin'],
    display: 'swap',
});

const DynamicToastProvider = dynamic(() => import('@/components/toast-container/ToastProvider'), {
    ssr: false,
});

const MyApp = ({ Component, pageProps, ...rest }: AppPropsWithLayout) => {
    const { store } = wrapper.useWrappedStore(rest);
    const getLayout = Component.getLayout ?? ((page: any) => page);

    return (
        <AuthProvider>
            <Provider store={store}>
                <div className={poppins.className}>
                    {getLayout(<Component {...pageProps} />)}
                    <DynamicToastProvider
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </Provider>
        </AuthProvider>
    );
};

export default wrapper.withRedux(MyApp);
