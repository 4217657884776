interface BookingsState {
    refreshBookings: boolean;
    // Add other properties as needed
}

const initialState: BookingsState = {
    refreshBookings: false,
};

const bookingsReducer = (state = initialState, action: any): BookingsState => {
    switch (action.type) {
        case 'REFRESH_BOOKINGS':
            return {
                ...state,
                refreshBookings: action.refresh,
            };
        default:
            return state;
    }
};

export default bookingsReducer;
